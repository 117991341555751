body {
	margin: 0px 0px;
	padding: 0px;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	color: #3c3c3c;
	line-height: 18px;
}

* {
	margin: 0px;
	padding: 0px;
	outline: none;
}

.padA {
	padding: 0px;
}

.padA-10 {
	padding: 10px;
}

.hor-line-dotted {
	border-bottom: 1px #dcdcdc dotted !important;
}

.border-line-dotted {
	border: 1px #dcdcdc dotted !important;
}

.border-1-input {
	border: 1px #ced4da dotted;
}

.text-underline {
	text-decoration: underline;
}

.text-bold {
	font-weight: 600;
}

.red-text-color {
	color: #E75A5A;
}

.black-text-color {
	color: #4E4E4E !important;
}

.grey-text-color {
	color: #A0A0A0 !important;
}

.green-text-color {
	color: #53D95C !important;
}

.yellow-text-color {
	color: #E7D95A !important;
}

.blue-text {
	color: #0094FF !important;
}

.light-grey-bg-color {
	background: #F7F7F7 !important;
}

.blue-bg-color {
	background: #0E90D1 !important;
}

.blue-broder-color {
	border: 1px solid #0E90D1 !important;
}

.position-relative {
	position: relative;
}

.position-absolute {
	position: absolute;
}

.w-5 {
	width: 5%;
}

.w-10 {
	width: 10%;
}

.w-15 {
	width: 15%;
}

.w-20 {
	width: 20%;
}

.w-25 {
	width: 25%;
}

.w-35 {
	width: 35%;
}

.w-50 {
	width: 50%;
}

.w-70 {
	width: 70%;
}

.h-100 {
	min-height: 100px;
}

.h-200 {
	min-height: 200px;
}

.text-dark {
	color: #4E4E4E;
}

.text-grey {
	color: #999999;
}

.dis-flex {
	display: flex;
}

.border-0 {
	border: none;
}

.border-grey-1 {
	border: 1px #dcdcdc solid;
}

.bg-transparent {
	background: transparent;
}

.font-size-12 {
	font-size: 12px;
}

.font-size-14 {
	font-size: 14px;
}

.font-size-16 {
	font-size: 16px;
}

.font-size-18 {
	font-size: 18px;
}

.font-size-20 {
	font-size: 20px;
}

.font-size-22 {
	font-size: 22px;
}

.font-size-24 {
	font-size: 24px;
}

.font-size-26 {
	font-size: 26px;
}

.font-size-28 {
	font-size: 28px;
}

.font-weight-7 {
	font-weight: 500;
}

.font-weight-4 {
	font-weight: 400;
}

.font-weight-3 {
	font-weight: 400;
}

.display-block {
	display: block;
}

.display-inline-block {
	display: inline-block;
}

.display-none {
	display: none;
}

.pad-rl-5 {
	padding-left: 5px;
	padding-right: 5px;
}

.pad-r-5 {
	padding-right: 5px;
}

.orange-bg {
	background: #F58714;
}

.blue-light-bg {
	background: #00C7BF;
}

.blue-dark-bg {
	background: #512DA7;
}

.blue-bg {
	background: #0067DB;
}

.purple-bg {
	background: #9B01BF;
}

.red-bg {
	background: #EA2835;
}

.container-fluid container-xl {
	width: 90% !important;
	margin: 0px auto;
}

/*********************************/

.header-custom-main {
	margin: 0px 0px;
	padding: 0px 0px;
	background: rgb(205,229,247);
	background: linear-gradient(288deg, rgba(205,229,247,1) 0%, rgba(208,236,249,1) 55%, rgba(255,255,255,1) 98%);
}

.custom-top-header {
	display: flex;
	justify-content: space-between;
	padding: 20px 0px;
}

.sign-up-btn {
	font-size: 16px;
	font-weight: 500;
	color: #001A5C;
	border: 1px #001A5C solid;
	min-height: 44px;
	min-width: 95px;
	text-align: center;
	line-height: 30px;
	border-radius: 30px;
	background: transparent;
}

.campaign-page-btn {
	font-size: 16px;
	font-weight: 500;
	color: #fff;
	min-height: 38px;
	padding: 0px 30px;
	width: 100%;
	text-align: center;
	line-height: 30px;
	border-radius: 30px;
	background: #0094FF;
	border: 1px #0094FF solid;
}

.login-page-btn {
	font-size: 20px;
	font-weight: 500;
	color: #fff;
	min-height: 71px;
	width: 100%;
	text-align: center;
	line-height: 30px;
	border-radius: 30px;
	background: #0094FF;
	border: 1px #0094FF solid;
}

.saved-create-btn {
	font-size: 18px;
	font-weight: 500;
	color: #fff;
	min-height: 40px;
	text-align: center;
	display: flex;
	padding: 0px 30px;
	line-height: 35px;
	border-radius: 30px;
	background: #0094FF;
	border: 1px #0094FF solid;
	float: right;
}

.add-saved-btn {
	font-size: 18px;
	font-weight: 500;
	color: #fff;
	min-height: 40px;
	text-align: center;
	display: flex;
	padding: 0px 30px;
	line-height: 35px;
	border-radius: 30px;
	background: #0094FF;
	border: 1px #0094FF solid;
	display: flex;
	gap: 10px;
	justify-content: center;
	align-items: center;
}

.lt-pannel-head {
	display: flex;
	justify-content: start;
	gap: 20px;
	margin: 0px 0px 0px 0px;
	align-items: center;
}

	.lt-pannel-head h3 {
		font-size: 24px;
		font-weight: 500;
		margin: 0px 0px;
		padding: 0px 0px;
		color: #001A5C;
	}

	.lt-pannel-head figure {
		margin: 0px 0px;
		padding: 0px 0px;
		width: 60px;
	}

		.lt-pannel-head figure img {
			width: 100%;
		}

.login-back-btn {
	font-size: 20px;
	font-weight: 500;
	color: #001A5C;
	min-height: 71px;
	width: 40%;
	text-align: center;
	line-height: 30px;
	border-radius: 30px;
	background: transparent;
	border: 1px #001A5C solid;
}


/******************/
.leaderboad-list-main-contianer {
	border-radius: 25px;
	background: #fff;
	border: 1px #E7E7E7 solid;
	padding: 20px 30px;
}

.leaderboard-header-sec {
	display: flex;
	justify-content: space-between;
	gap: 0px;
	margin: 0px 0px 20px 0px;
	align-items: center;
	padding: 0px 0px;
}

.campaign-user-top-sec {
	display: flex;
	margin: 40px 0px 40px 0px;
	gap: 15px;
	border-right: 1px #E1E1E1 solid;
	align-items: center;
}

	.campaign-user-top-sec:last-of-type {
		border-right: none;
	}

	.campaign-user-top-sec figure {
		width: 54px;
		height: 54px;
		border-radius: 50%;
		background: #0094FF;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
		margin: 0px 0px 0px 0px;
	}

	.campaign-user-top-sec p {
		font-size: 16px;
		font-weight: 500;
		color: #5F718D;
		margin: 0px 0px 10px 0px;
		padding: 0px 0px;
	}

	.campaign-user-top-sec h3 {
		font-size: 22px;
		font-weight: 600;
		color: #001A5C;
		margin: 0px 0px;
		padding: 0px 0px;
	}


.cus-flex {
	display: flex;
	gap: 10px;
}

.custom-table-main {
	margin: 0px 0px;
	padding: 0px 0px;
}

	.custom-table-main tr th {
		padding: 20px 0px;
		font-size: 16px;
		font-weight: 500;
		color: #5F718D;
		border-top: none !important;
	}

	.custom-table-main tr td {
		padding: 20px 0px;
		font-size: 16px;
		font-weight: 400;
		color: #001A5C;
		vertical-align: middle;
	}

.logout-rt-top-sec {
	display: flex;
	gap: 22px;
	align-items: center;
	margin: 0px 0px;
	padding: 0px 0px;
}

	.logout-rt-top-sec button {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: #B8E0FF;
		border: 1px #B8E0FF solid;
		margin: 0px 0px;
		padding: 0px 0px;
	}

	.logout-rt-top-sec h5 {
		font-size: 18px;
		font-weight: 500;
		color: #001A5C;
		margin: 0px 0px;
		padding: 0px 0px;
	}


.main-middle-content-section {
	margin: 0px 0px;
}

.why-joinus-content-sec {
	display: flex;
	justify-content: space-between;
	margin: 0px 0px;
	padding: 0px 0px;
}

.joinus-content-sec {
	width: 60%;
	margin: 0px 0px;
	padding: 0px 50px 0px 0px;
	position: relative;
	top: 70px;
}

	.joinus-content-sec h4 {
		margin: 0px 0px 10px 0px;
		padding: 0px 0px;
		font-size: 18px;
		color: #0085FF;
		font-weight: 500;
	}

	.joinus-content-sec h2 {
		margin: 30px 0px 10px 0px;
		padding: 0px 0px;
		font-size: 46px;
		color: #001A5C;
		font-weight: 400;
		line-height: 50px;
	}

	.joinus-content-sec p {
		margin: 0px 0px 0px 0px;
		padding: 0px 0px;
		font-size: 14px;
		color: #5F718D;
		font-weight: 400;
		line-height: 24px;
	}

.why-joinus-content-sec figure {
	margin: 0px 0px;
	padding: 0px 40px;
	width: 40%;
}

	.why-joinus-content-sec figure img {
		width: 100%;
		margin: 0px;
		padding: 0px 0px;
	}

.login-content-sec {
	position: relative;
	top: 70px;
	margin: 0px 0px;
	padding: 0px;
}

.right-btn-sec {
	display: flex;
	align-items: center;
	gap: 20px;
	margin: 0px;
	padding: 0px;
}

.already-acc-btn {
	font-size: 16px;
	color: #5F718D;
	margin: 0px;
	padding: 0px;
}

.recover-pass-content-sec {
	position: relative;
	top: 270px;
	margin: 0px 0px;
	padding: 0px;
}

.verify-content-sec {
	position: relative;
	top: 200px;
	margin: 0px 0px;
	padding: 0px 50px;
	text-align: center;
}

	.verify-content-sec h4 {
		font-size: 40px;
		font-weight: 400;
		margin: 0px 0px 30px 0px;
		padding: 0px 0px;
		color: #001A5C;
		line-height: 43px;
	}

	.verify-content-sec p {
		font-size: 16px;
		font-weight: 400;
		margin: 0px 0px 50px 0px;
		padding: 0px 0px;
		color: #5F718D;
		line-height: 27px;
	}

.change-pass-content-sec {
	position: relative;
	top: 200px;
	margin: 0px 0px;
	padding: 0px;
}

.login-content-sec h4 {
	font-size: 40px;
	font-weight: 400;
	margin: 0px 0px 20px 0px;
	padding: 0px 0px;
	color: #001A5C;
}

.reword-content-sec {
	margin: 0px;
	padding: 0px 0px;
	position: relative;
	top: 70px;
}

	.reword-content-sec p {
		font-size: 16px;
		font-weight: 400;
		margin: 0px 0px 30px 0px;
		padding: 0px 0px;
		color: #5F718D;
	}

	.reword-content-sec h4 {
		font-size: 40px;
		font-weight: 400;
		margin: 0px 0px 20px 0px;
		padding: 0px 0px;
		color: #001A5C;
	}

	.reword-content-sec h5 {
		font-size: 20px;
		font-weight: 500;
		margin: 10px 0px 10px 0px;
		padding: 0px 0px;
		color: #001A5C;
	}

	.reword-content-sec p {
		font-size: 16px;
		font-weight: 400;
		margin: 0px 0px 30px 0px;
		padding: 0px 0px;
		line-height: 25px;
		color: #5F718D;
	}

.social-all-icon p {
	color: #001A5C;
	font-size: 16px;
	font-weight: 400;
	margin: 0px 0px 30px 0px;
	padding: 0px 0px;
	line-height: 25px;
}

.social-all-icon ul {
	list-style: none;
	margin: 0px 0px;
	padding: 0px 0px;
}

	.social-all-icon ul li {
		width: 55px;
		height: 55px;
		border-radius: 50%;
		background: #0094FF;
		color: #fff;
		text-align: center;
		display: inline-block;
		padding: 14px 0px 0px 0px;
		margin: 0px 20px 20px 0px;
	}

		.social-all-icon ul li a {
			color: #fff;
			font-size: 26px;
		}

.login-mess-container {
	width: 60%;
	min-height: 73px;
	border: 1px #37e2c3 solid;
	border-radius: 10px;
	background: #d1fbf3;
	font-size: 16px;
	font-weight: 400;
	color: #001A5C;
	padding: 24px;
	margin-bottom: 20px;
}

.forgot-password-sec {
	display: flex;
	align-items: center;
	color: #5F718D;
	gap: 10px;
	font-size: 14px;
	padding: 20px 0px;
	font-weight: 400;
}

	.forgot-password-sec a {
		color: #5F718D;
		margin: 20px 0px;
	}

.have-account-already {
	font-size: 16px;
	font-weight: 400;
	color: #5F718D;
	margin-top: 30px;
}

.keep-sign-text {
	padding: 20px 0px !important;
}

	.keep-sign-text label {
		display: flex;
		gap: 20px;
		font-size: 14px;
		padding: 0px 0px;
		font-weight: 400;
		margin-bottom: 20px;
	}

.login-input-content-sec {
	margin: 0px 0px;
	padding: 0px 0px;
	display: flex;
	justify-content: space-between;
}

.section-speretor {
	display: flex;
	gap: 20px;
	align-items: center;
	margin: 30px 0px;
}

	.section-speretor hr {
		border: 1px #E0E0E0 solid;
		width: 45%;
	}

	.section-speretor span {
		color: #CDCDCD;
		font-size: 16px;
		font-weight: 400;
	}

.input-section-main {
	margin: 20px 0px;
}

	.input-section-main label {
		font-size: 18px;
		font-weight: 500;
		color: #001A5C;
	}

	.input-section-main input[type=email], .input-section-main input[type=password], .input-section-main input[type=text], .input-section-main input[type=date] {
		width: 100%;
		height: 71px;
		border: 1px #DBDEE9 solid;
		padding: 0px 20px;
		border-radius: 10px;
	}

.deskstop-show {
	display: none;
}

/*********** media query start here *********/

@media screen and (max-width: 768px) {
}
